<template>
  <div class="page-classroom-view">
    <div class="my-page" style="padding-bottom: 45px">
      <!-- 上方详情 -->
      <div class="my-card detail-box">
        <!-- <div class="card-top">
          <div class="card-title ell">{{ viewData.Title }}</div>
        </div> -->
        <div class="my-card-top">
          <div class="left">
            <div class="role">
              <img class="pic" :src="viewData.createUserHeadUrl" @error="errorImg" />
            </div>
            <div class="info">
              <div class="info-head">
                {{ viewData.RealName }}
              </div>
            </div>
          </div>
          <div class="right">
            {{ viewData.CreateDate }}
          </div>
        </div>
        <div class="card-bottom">
          <div class="content">{{ viewData.Content }}</div>
          <div class="img-d">
            <van-image
              v-for="(img, index) in viewData.accessoryList"
              :key="index"
              width="100"
              height="74"
              :src="img.uploadUrl"
              @click.stop="
                () => {
                  images = [];
                  images.push(img.uploadUrl);
                  imgShow = true;
                }
              "
              style="
                margin-right: 10px;
                margin-bottom: 10px;
                border-radius: 6px;
                overflow: hidden;
                display: inline-block;
              "
            />
          </div>
        </div>
      </div>
      <!-- 评论区 -->
      <div class="my-card comment-box">
        <div class="comment-title">评论回复（{{ viewData.Count }}）</div>
        <div class="comment-list">
          <template
            v-if="viewData.replyDtoList && viewData.replyDtoList.length > 0"
          >
            <div
              v-for="(obj, index) in viewData.replyDtoList"
              :key="index"
              class="comment-item"
            >
              <div class="user-box">
                <div class="user">
                  <img :src="obj.HeadUrl" @error="errorImg" />
                  <span>{{ obj.CreateUserName }}</span>
                </div>
                <div class="date">{{ obj.CreateDate }}</div>
              </div>
              <div class="word">{{ obj.Content }}</div>
            </div>
          </template>
          <van-empty v-else description="暂无评论" />
        </div>
      </div>
      <!-- 提交评论（家长） -->
      <div class="submit-box">
        <van-button
          block
          color="#47AFA7"
          type="info"
          @click="dialogVisible = true"
          >回复</van-button
        >
      </div>

      <van-dialog
        v-model="dialogVisible"
        title="评论回复"
        :beforeClose="beforeClose"
        showCancelButton
      >
        <van-field
          required
          v-model="comment"
          name="comment"
          rows="5"
          label=""
          type="textarea"
          placeholder="请输入内容"
        />
      </van-dialog>
    </div>

    <!-- 图片预览 -->
    <van-image-preview v-model="imgShow" :images="images"> </van-image-preview>
  </div>
</template>

<script>
import defaultImg from "@/assets/icon/i-comment-defalut.png";
import {
  Image,
  ImagePreview,
  Empty,
  Icon,
  Checkbox,
  CheckboxGroup,
  Dialog,
  Button,
  Field
} from "vant";
export default {
  components: {
    [Image.name]: Image,
    [ImagePreview.Component.name]: ImagePreview.Component,
    [Empty.name]: Empty,
    [Icon.name]: Icon,
    [Checkbox.name]: Checkbox,
    [CheckboxGroup.name]: CheckboxGroup,
    [Dialog.Component.name]: Dialog.Component,
    [Button.name]: Button,
    [Field.name]: Field,
  },
  data() {
    return {
      userType: window.localStorage.getItem("UserType"),
      defaultImg: defaultImg,
      id: null,
      viewData: {},
      dataList: [{}, {}, {}, {}],
      comment: "",
      imgShow: false,
      images: [],
      result: [],
      dialogVisible: false,
    };
  },
  mounted() {
    this.id = this.$route.query.id;
    this.getDetail();
  },
  methods: {
    getDetail() {
      let that = this;
      this.$axios
        .get("/api/ParentMailbox/Get", {
          ID: this.id,
        })
        .then((res) => {
          if (res.code === 200) {
            this.viewData = res.data;
            this.$nextTick(() => {
              setTimeout(() => {
                // 动态改变滚动高度
                const dom = that.$jq(".comment-list");
                dom.css(
                  "height",
                  that.$jq(window).height() -
                    dom.offset().top -
                    30 -
                    45 -
                    15 -
                    15
                );
              }, 200);
            });
          } else {
            that.$toast.fail(res.msg || "操作失败");
          }
        });
    },

    // 评论
    Reply() {
      this.dialogVisible = true;
    },
    test() {
      this.$axios
        .post("/api/ParentMailbox/Reply", {
          ParentMailboxID: this.viewData.ID,
          Content: this.comment,
        })
        .then((res) => {
          if (res.code === 200) {
            this.comment = "";
            this.$toast.success("回复成功");
            // this.$router.go(0)
            window.location.reload();
          } else {
            this.$toast.fail(res.msg || "操作失败");
          }
        });
    },
    beforeClose(action, done) {
      let that = this;
      if (action === "confirm") {
        if (!this.comment) {
          this.$toast.fail("请填写回复内容！");
          done(false);
        } else {
          this.$axios
            .post("/api/ParentMailbox/Reply", {
              ParentMailboxID: this.viewData.ID,
              Content: this.comment,
            })
            .then((res) => {
              if (res.code === 200) {
                this.$toast.success(res.msg || "操作成功");
                this.comment = "";
                done();
                setTimeout(() => {
                   window.location.reload();
                }, 1000);
              } else {
                done(false);
                this.$toast.fail(res.msg || "操作失败");
              }
            });
        }
      } else {
        this.comment = "";
        done();
      }
    },
    errorImg () {
      const img = event.srcElement
      img.src = this.defaultImg
      img.onerror = null
    }
  },
};
</script>
<style lang="less" scoped>
@import "./index.less";
</style>
